<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					 {{t('10251')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="box">
					<div class="favour">
						<div class="favour__notice">
							{{t('10252')}}
						</div>
						<div class="favour__wrap">
							<!-- 일반 -->
							<div class="favour__item">
								<div class="favour__box">
									<div class="favour__icon">
										{{t('10253')}}
									</div>
								</div>
								<div class="favour__box">
									<h4 class="favour__title">
										{{t('10254')}}
									</h4>
									<p>{{t('10255')}}</p>
								</div>
							</div>
							<!-- VIP -->
							<div class="favour__item">
								<div class="favour__box">
									<div class="favour__icon favour__icon--vip">
										VIP
									</div>
								</div>
								<div class="favour__box">
									<h4 class="favour__title">
										{{t('10256')}}
									</h4>
									<p>
										{{t('10257')}}
									</p>
									<p>
										{{t('10258')}}
									</p>
								</div>
							</div>
							<!-- VVIP -->
							<div class="favour__item">
								<div class="favour__box">
									<div class="favour__icon favour__icon--vvip">
										VVIP
									</div>
								</div>
								<div class="favour__box">
									<h4 class="favour__title">
										VVIP
									</h4>
									<p>
										전년도 이용 금액 100만원 이상 <br>
										AND 구매 건수 20건 이상
										<!-- 재번역 체크 -->
									</p>
									<p>
										숙소 50%할인 혜택 (총 5회)
										<!-- 재번역 체크 -->
									</p>
								</div>
							</div>
						</div>
						<div class="favour__info">
							※ 모든 회원등급 유지기간은 1년 입니다.
										<!-- 재번역 체크 -->
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	setup: function() {
    const router = useRouter()
    const { t }= useI18n() //번역필수 모듈
    const goBack = () => {
      router.go(-1)
    }

		return {
			goBack,
			t, //번역필수 모듈
			i18n,
		}
	}
};
</script>>